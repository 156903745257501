import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/footer";
import Home from "./components/Home";
import Holidays from "./components/Holidays";
import RuleOfThree from "./components/RuleOfThree";
import PrivacyPolicy from "./components/PrivacyPolicy";
import MusicTrendsPage from "./components/MusicTrendsPage";
import GoogleAds from "./components/GoogleAds";
import Cursor from "./components/Cursor";


import "./App.css";

const App = () => {
  return (
    <Router>
      <div className="app">
      <Cursor />

        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/holidays" element={<><GoogleAds /><Holidays /></>} />
            <Route path="/rule-of-three" element={<><GoogleAds /><RuleOfThree /></>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/music-releases" element={<><GoogleAds /><MusicTrendsPage /></>} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;