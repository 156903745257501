import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GoogleAdsense = () => {
  const location = useLocation();

  useEffect(() => {
    // Define the pages where ads should NOT be displayed
    const excludedPaths = ["/", "/privacy-policy"];

    if (!excludedPaths.includes(location.pathname)) {
      // Dynamically add the AdSense script only for allowed pages
      const script = document.createElement("script");
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4640591753872176";
      script.crossOrigin = "anonymous";
      script.async = true;
      document.head.appendChild(script);

      // Clean up the script when the component unmounts or route changes
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [location]);

  return null; // This component doesn't render anything visible
};

export default GoogleAdsense;