// src/components/Cursor.js
import React, { useEffect, useRef } from "react";
import { TweenMax } from "gsap";
import Dot from "./Dot";
import "./Cursor.css";

const Cursor = () => {
  const cursorRef = useRef(null);

  useEffect(() => {
    // -----------------------
    // CONFIG / CONSTANTS
    // -----------------------
    const amount = 20;                     // number of dots
    const sineDots = Math.floor(amount * 0.3); // how many dots do idle wave
    const dotSize = 26;                    // each dot's base size in px
    const idleTimeout = 500;               // ms until we go idle

    // Mouse position and idle state
    let mouse = { x: -1000, y: -1000 };
    let idle = false;
    let timeoutId;
    let lastFrame = 0;
    // Ref to pass idle state to Dot instances
    const idleRef = { current: false };

    // -----------------------
    // Create Dots
    // -----------------------
    const dots = [];
    for (let i = 0; i < amount; i++) {
      dots.push(new Dot(i, cursorRef.current, dotSize));
    }

    // -----------------------
    // Idle Timer
    // -----------------------
    const goInactive = () => {
      idle = true;
      idleRef.current = true;
      // lock each dot's position for idle animation
      dots.forEach((dot) => dot.lock());
    };
    const resetIdleTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(goInactive, idleTimeout);
      idle = false;
      idleRef.current = false;
    };
    resetIdleTimer();

    // -----------------------
    // MOUSE & TOUCH EVENTS
    // -----------------------
    const handleMouseMove = (e) => {
      mouse.x = e.clientX - dotSize / 2;
      mouse.y = e.clientY - dotSize / 2;
      resetIdleTimer();
    };
    const handleTouchMove = (e) => {
      const t = e.touches[0];
      mouse.x = t.clientX - dotSize / 2;
      mouse.y = t.clientY - dotSize / 2;
      resetIdleTimer();
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);

    // -----------------------
    // Animation Render Loop
    // -----------------------
    const render = (timestamp) => {
      const delta = timestamp - lastFrame;
      lastFrame = timestamp;
      positionDots(delta);
      requestAnimationFrame(render);
    };
    const positionDots = () => {
      let x = mouse.x;
      let y = mouse.y;
      dots.forEach((dot, i) => {
        let nextDot = dots[i + 1] || dots[0];
        dot.x = x;
        dot.y = y;
        dot.draw(idleRef, sineDots);
        if (!idle || i <= sineDots) {
          const dx = (nextDot.x - dot.x) * 0.35;
          const dy = (nextDot.y - dot.y) * 0.35;
          x += dx;
          y += dy;
        }
      });
    };

    lastFrame = performance.now();
    requestAnimationFrame(render);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      {/* The Gooey SVG filter */}
      <svg width="0" height="0">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0 
                      0 1 0 0 0 
                      0 0 1 0 0 
                      0 0 0 35 -15"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>

      {/* Container for all dot elements */}
      <div className="Cursor" ref={cursorRef}></div>
    </>
  );
};

export default Cursor;