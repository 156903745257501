import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          Made by <strong>Paraskevakis</strong>. All rights ( and lefts )  reserved.
        </p>
        <Link to="/privacy-policy" className="footer-link">
          Privacy Policy
        </Link>
      </div>
      <div className="cat-container">
        <div className="cat">
          <div className="ear ear--left"></div>
          <div className="ear ear--right"></div>
          <div className="face">
            <div className="eye eye--left">
              <div className="eye-pupil"></div>
            </div>
            <div className="eye eye--right">
              <div className="eye-pupil"></div>
            </div>
            <div className="muzzle"></div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;