const clientId = "44b9d04d02df40c897291dc323795d4f"; // Your provided Client ID
const clientSecret = "d388b3d2c9e3480c9c0c3a38b91eac3a"; // Your provided Client Secret

const getAccessToken = async () => {
  const tokenUrl = "https://accounts.spotify.com/api/token";
  const credentials = btoa(`${clientId}:${clientSecret}`); // Base64 encode Client ID and Client Secret

  const response = await fetch(tokenUrl, {
    method: "POST",
    headers: {
      Authorization: `Basic ${credentials}`, // Add Authorization header
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: "grant_type=client_credentials", // Specify grant type
  });

  if (!response.ok) {
    throw new Error("Failed to fetch access token");
  }

  const data = await response.json();
  return data.access_token; // Return the access token
};

export const fetchTrendingTracks = async () => {
  try {
    const accessToken = await getAccessToken(); // Fetch access token
    const url = "https://api.spotify.com/v1/browse/new-releases";

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`, // Add Bearer token for authorization
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch trending tracks");
    }

    const data = await response.json();
    return data.albums.items; // Return array of new releases
  } catch (error) {
    console.error("Error fetching trending tracks:", error);
    return [];
  }
};