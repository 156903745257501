// src/components/Dot.js
import { TweenMax } from "gsap";

export default class Dot {
  constructor(index, cursorContainer, dotSize) {
    this.index = index;
    this.x = 500;
    this.y = 500;
    // For idle wave
    this.angleX = 0;
    this.angleY = 0;
    this.anglespeed = 0.05;

    // Scale each dot slightly smaller for the older ones
    this.scale = 1 - 0.05 * index;

    // The "range" for idle movement
    this.range = dotSize / 2 - (dotSize / 2) * this.scale + 2;

    // Create the DOM element
    this.el = document.createElement("span");
    // Scale it
    TweenMax.set(this.el, { scale: this.scale });
    // Add it to our cursor container
    cursorContainer.appendChild(this.el);
  }

  // Lock the dot's position for idle wave movement
  lock() {
    this.lockX = this.x;
    this.lockY = this.y;
    // random angles so each dot drifts differently
    this.angleX = Math.PI * 2 * Math.random();
    this.angleY = Math.PI * 2 * Math.random();
  }

  // Draw the dot. Accept idleRef to check idle state and sineDots to control idle dots.
  draw(idleRef, sineDots) {
    if (!idleRef.current || this.index <= sineDots) {
      // Normal: simply place the dot at (x, y)
      TweenMax.set(this.el, { x: this.x, y: this.y });
    } else {
      // IDLE wave: move around the locked position
      this.angleX += this.anglespeed;
      this.angleY += this.anglespeed;
      this.y = this.lockY + Math.sin(this.angleY) * this.range;
      this.x = this.lockX + Math.sin(this.angleX) * this.range;
      TweenMax.set(this.el, { x: this.x, y: this.y });
    }
  }
}