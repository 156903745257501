import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "./Holidays.css";

const holidays = [
  { name: "Christmas", date: new Date(new Date().getFullYear(), 11, 25) },
  { name: "New Year", date: new Date(new Date().getFullYear() + 1, 0, 1) },
  { name: "Easter", date: new Date(new Date().getFullYear(), 3, 9) },
  { name: "Independence Day", date: new Date(new Date().getFullYear(), 6, 4) },
  { name: "Halloween", date: new Date(new Date().getFullYear(), 9, 31) },
];

const HolidayCountdown = ({ holiday }) => {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(holiday.date));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(holiday.date));
    }, 1000);

    return () => clearInterval(timer);
  }, [holiday.date]);

  function getTimeLeft(targetDate) {
    const now = new Date();
    if (now > targetDate) {
      targetDate.setFullYear(targetDate.getFullYear() + 1);
    }
    const diff = targetDate - now;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  function formatDate(date) {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  return (
    <div className="holiday-card">
      <div className="holiday-name">{holiday.name}</div>
      <div className="holiday-date">{formatDate(holiday.date)}</div>
      <div className="holiday-time">
        {timeLeft.days} Days, {timeLeft.hours} Hours, {timeLeft.minutes}{" "}
        Minutes, {timeLeft.seconds} Seconds
      </div>
    </div>
  );
};

const Holidays = () => {
  return (
    <>
      <Helmet>
        <title>🎉 Holiday Countdown | Paraskevakis Tools</title>
        <meta
          name="description"
          content="Countdown to all major holidays including Christmas, New Year, Easter, Halloween, and more. Keep track of your favorite festive moments!"
        />
        <meta
          name="keywords"
          content="Holiday Countdown, Christmas Countdown, New Year Countdown, Easter Countdown, Halloween Countdown, Festive Timers"
        />
        <meta property="og:title" content="🎉 Holiday Countdown | Paraskevakis Tools" />
        <meta
          property="og:description"
          content="Keep track of all your favorite holidays like Christmas, New Year, Easter, and more with live countdowns!"
        />
        <meta
          property="og:image"
          content="https://paraskevakis.com/image.jpg"
        />
        <meta property="og:url" content="https://paraskevakis.com/holidays" />
        <meta property="og:type" content="website" />
      </Helmet>
      <main>
        <h1>🎉 Holiday Countdown 🎉</h1>
        <section className="container">
          {holidays.map((holiday, index) => (
            <HolidayCountdown key={index} holiday={holiday} />
          ))}
        </section>
      </main>
    </>
  );
};

export default Holidays;