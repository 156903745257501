import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import "./RuleOfThree.css";

const RuleOfThree = () => {
  const [a, setA] = useState("");
  const [b, setB] = useState("");
  const [c, setC] = useState("");
  const [x, setX] = useState(null);

  const calculateX = () => {
    if (a && b && c) {
      const result = (c * b) / a;
      setX(result.toFixed(2));
    } else {
      setX(null);
    }
  };

  return (
    <>
      <Helmet>
        <title>Rule of Three Calculator | Paraskevakis Tools</title>
        <meta name="description" content="Use our simple Rule of Three Calculator to find the missing value (X) with ease." />
        <meta name="keywords" content="rule of three, rule of three calculator, simple calculator, math tool" />
        <meta name="author" content="Paraskevakis" />
      </Helmet>
      <main className="rule-container">
        <h1>Simple Rule of Three Calculator</h1>
        <div className="rule-card">
          <p>Enter three values below to calculate the missing value (X).</p>
          <div className="inputs">
            <div className="input-group">
              <label htmlFor="input-a">At</label>
              <input
                id="input-a"
                type="number"
                value={a}
                onChange={(e) => setA(e.target.value)}
                placeholder="e.g., 2"
              />
            </div>
            <div className="input-group">
              <label htmlFor="input-b">We have</label>
              <input
                id="input-b"
                type="number"
                value={b}
                onChange={(e) => setB(e.target.value)}
                placeholder="e.g., 8"
              />
            </div>
          </div>
          <div className="inputs">
            <div className="input-group">
              <label htmlFor="input-c">At</label>
              <input
                id="input-c"
                type="number"
                value={c}
                onChange={(e) => setC(e.target.value)}
                placeholder="e.g., 6"
              />
            </div>
            <div className="input-group">
              <label htmlFor="calculate-x">How much?</label>
              <button id="calculate-x" onClick={calculateX}>
                Calculate X
              </button>
            </div>
          </div>
          {x !== null && (
            <div className="result">
              <h2>Result</h2>
              <h3>X = {x}</h3>
              <p>
                X = ({c} × {b}) ÷ {a}
              </p>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default RuleOfThree;