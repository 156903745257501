import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Last Updated: December 28, 2024</p>
      <p>
        At Paraskevakis Tools, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose data when you use our website.
      </p>

      <h2>Information We Collect</h2>
      <ul>
        <li>
          <strong>Google Analytics:</strong> We use Google Analytics to collect non-personal data about website usage, such as pages visited, time spent, and interactions. This helps us improve our services.
        </li>
        <li>
          <strong>AdSense:</strong> Our website uses Google AdSense to display ads. Google may use cookies to provide targeted advertising based on your interests.
        </li>
        <li>
          <strong>User Interaction:</strong> Data entered in tools like the Holiday Countdown and Rule of Three Calculator is not stored or shared.
        </li>
      </ul>

      <h2>Cookies</h2>
      <p>
        Our site uses cookies to enhance your experience. These cookies include:
        <ul>
          <li>Google Analytics cookies for usage tracking.</li>
          <li>AdSense cookies for personalized ads.</li>
        </ul>
        You can disable cookies through your browser settings or opt-out of personalized ads via <a href="https://adssettings.google.com/" target="_blank" rel="noopener noreferrer">Google Ad Settings</a>.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        We use third-party services, including Google Analytics and AdSense, which may collect and process data according to their own privacy policies. Please review their policies for more information:
      </p>
      <ul>
        <li>
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            Google Privacy Policy
          </a>
        </li>
      </ul>

      <h2>Your Choices</h2>
      <p>
        You can manage your data by:
        <ul>
          <li>Opting out of Google Analytics using the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a>.</li>
          <li>Disabling cookies in your browser settings.</li>
        </ul>
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at support@paraskevakis.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;