import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        {/* Logo */}
        <div className="logo-container">
          <Link to="/" onClick={() => setMenuOpen(false)}>
            <img 
              src="/paraskevakis_logo.webp" 
              alt="Paraskevakis Logo" 
              className="logo" 
              width="50" 
              height="50" 
            />
          </Link>
        </div>

        {/* Burger Menu */}
        <button className="burger-menu" onClick={toggleMenu}>
          ☰
        </button>

        {/* Navigation Links */}
        <ul className={`nav-links ${menuOpen ? "open" : ""}`}>
          <li>
            <Link to="/" onClick={() => setMenuOpen(false)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/holidays" onClick={() => setMenuOpen(false)}>
              Holidays Countdown
            </Link>
          </li>
          <li>
            <Link to="/rule-of-three" onClick={() => setMenuOpen(false)}>
              Rule of Three
            </Link>
          </li>
          <li>
            <Link to="/music-releases" onClick={() => setMenuOpen(false)}>
              New Music Releases
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;