import React from "react";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Welcome to Paraskevakis Tools</title>
        <meta
          name="description"
          content="Explore tools like the Holiday Countdown and Rule of Three Calculator. Useful utilities for everyday needs!"
        />
        <meta name="keywords" content="tools, utilities, holiday countdown, rule of three calculator" />
        <meta property="og:title" content="Welcome to Paraskevakis Tools" />
        <meta
          property="og:description"
          content="Explore tools like the Holiday Countdown and Rule of Three Calculator. Useful utilities for everyday needs!"
        />
        <meta property="og:url" content="https://paraskevakis.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <main className="container">
        <h1>Welcome to Paraskevakis Tools</h1>
        <p>Select one of our tools from the menu to get started:</p>
        <ul>
          <li>Holidays Countdown</li>
          <li>Simple Rule of Three</li>
          <li>And more coming soon!</li>
        </ul>
      </main>
    </>
  );
};

export default Home;