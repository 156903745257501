import React, { useEffect, useState } from "react";
import { fetchTrendingTracks } from "../api/spotifyApi"; // Ensure SpotifyAPI.js exports fetchTrendingTracks
import "./MusicTrends.css"; // Create this CSS file for styling

const MusicTrendsPage = () => {
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const fetchTracks = async () => {
      const fetchedTracks = await fetchTrendingTracks();
      setTracks(fetchedTracks);
    };

    fetchTracks();
  }, []);

  return (
    <div className="trends-container">
      <h1>Music Trends</h1>
      <div className="tracks">
        {tracks.map((track, index) => (
          <div className="track" key={index}>
            <img
              src={track.images[0]?.url || "default-image.jpg"} // Fallback image
              alt={track.name}
              className="track-image"
            />
            <h3>{track.name}</h3>
            <p>{track.artists[0]?.name}</p>
            <a
              href={track.external_urls.spotify}
              target="_blank"
              rel="noopener noreferrer"
            >
              Listen on Spotify
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MusicTrendsPage;